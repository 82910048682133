import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { compose } from 'redux';
import { Avatar, NamedLink, SecondaryButton } from '../../components';
import { useBusinessUserCheck } from '../../hooks/useBussinessUserCheck';
import { injectIntl } from '../../util/reactIntl';
import css from './ListingPage.module.css';

function SectionOfferedBy(props) {
  const { authorLink, isOwnListing, intl, onContactUser, author, listing } = props;
  const businessUserCheck = useBusinessUserCheck();
  const isBusinessUser = businessUserCheck.check(author.id.uuid);

  const renderContactButton = () =>
    !isOwnListing ? (
      <SecondaryButton rootClassName={css.contactSellerButton} onClick={() => onContactUser(author, listing)}>
        {intl.formatMessage({ id: 'listing_page.contact_seller' })}
      </SecondaryButton>
    ) : null;

  return (
    <Stack sx={{ my: 3, mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <Typography
          component="label"
          sx={{
            m: 0,
            p: 0,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#212529',
          }}
        >
          {intl.formatMessage({ id: 'listing_page.offered_by.label' })}
        </Typography>
      </Box>
      <Box
        sx={{
          height: '56px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mb: 1,
        }}
      >
        <Avatar user={author} />
        <NamedLink name="ProfilePage" params={{ id: author.id.uuid }}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              p: 0,
              gap: 0.5,
              height: '52px',
              cursor: 'pointer',
            }}
          >
            <Typography component="span" sx={{ color: '#6c757d' }}>
              {intl.formatMessage({ id: 'listing_page.sold_by' }, { name: authorLink })}
            </Typography>
            {isBusinessUser && (
              <Chip
                label={
                  <Typography color="text.primary" fontSize="14px">
                    {intl.formatMessage({ id: 'listing_page.commercial_seller' }) || 'Commercial seller'}
                  </Typography>
                }
                size="small"
                sx={{
                  height: 26,
                  minHeight: 26,
                  backgroundColor: '#EAF1FD',
                  borderRadius: '20px',
                  px: 0.5,
                  cursor: 'pointer',
                }}
              />
            )}
          </Stack>
        </NamedLink>
      </Box>
      <Box mt={2}>{renderContactButton()}</Box>
    </Stack>
  );
}

export default compose(injectIntl)(SectionOfferedBy);
