import { useCallback, useEffect, useState } from 'react';
import { useApiCallback } from './useApi';

export const useBusinessUserCheck = () => {
  const [cachedUsers, setCachedUsers] = useState(null);
  const businessUsers = useApiCallback(api => api.businessUsers());

  useEffect(() => {
    // Try to get cached data from sessionStorage
    const cachedData = sessionStorage.getItem('bu_ids');

    // No cached data, fetch from API
    if (!cachedData) {
      businessUsers.execute().then(result => {
        if (result?.data?.users) {
          sessionStorage.setItem('bu_ids', JSON.stringify(result.data.users));
        }
      });
      return;
    }

    // Try to parse cached data
    try {
      const parsedData = JSON.parse(cachedData);
      setCachedUsers(parsedData);
      return;
    } catch (error) {
      console.error('Error parsing cached business users:', error);
    }

    // If parsing failed, fetch fresh data
    businessUsers.execute();
  }, []);

  const check = useCallback(
    authorId => {
      // Use cached users if available, otherwise use API response
      const users = cachedUsers || businessUsers.data?.users;
      return users ? users.some(id => id === authorId) : false;
    },
    [cachedUsers, businessUsers.data?.users]
  );

  return { check };
};
