import React from 'react';
import Linkify from 'react-linkify';
import Collapsible from '../../components/Collapsible/Collapsible';
import { brandsConfig } from '../../config/brands-config';
import { partsConfig } from '../../config/parts-config';
import css from './ListingPage.module.css';

const BIKE_REQUIRED_FIELDS = ['frameSize', 'condition', 'category', 'subCategory', 'wheelSize'];
const BIKE_GEAR_REQUIRED_FIELDS = ['category', 'subCategory', 'year'];
const WINTER_REQUIRED_FIELDS = ['category', 'subCategory', 'condition'];

const BIKE_MORE_DETAILS_FIELDS = ['model', 'year', 'frameMaterial', 'frameColor', 'receiptAvailable', 'refurbished', 'country'];

function SectionBasicInformation(props) {
  const { filters, publicData, description, intl } = props;
  const { category, subCategory, listingType, brand } = publicData;

  const availableSpecifications = () => {
    const subCategoryAttributes = partsConfig
      ?.find(config => config.id === listingType)
      ?.config?.options?.find(cat => cat.key === category)
      ?.subcategories?.find(subCat => subCat.key === subCategory)
      ?.specifications?.map(specification => specification.id);

    const categorySpecifications = partsConfig
      ?.find(config => config.id === listingType)
      ?.config?.options?.find(cat => cat.key === category)
      ?.specifications?.map(specification => specification.id);

    const specifications = subCategoryAttributes || categorySpecifications || [];

    return specifications.concat(['country']);
  };

  const pickExtendedData = filterConfig => (rows, key) => {
    const publicDataValue = publicData[key];
    if (publicDataValue) {
      const filterIfItExists = filterConfig.find(f => f.id === key);
      const filterOptions = filterIfItExists?.config?.options || [];

      const translationGroup = filterIfItExists?.translationGroup;
      const skipItemPageTranslations = filterIfItExists?.skipItemPageTranslations;

      if (key === 'category') {
        const label = undefined;
        const value = intl.formatMessage({ id: `categories.${category}` });

        return rows.concat({ key, value, label });
      }

      if (key === 'subCategory') {
        const label = undefined;
        const value = intl.formatMessage({ id: `categories.${subCategory}` });

        return rows.concat({ key, value, label });
      }

      const value =
        translationGroup && !skipItemPageTranslations
          ? intl.formatMessage({ id: `${translationGroup}.${publicDataValue}` })
          : filterOptions.find(o => o.key === publicDataValue)?.label || publicDataValue;

      const label = filterIfItExists?.label || `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }
    return rows;
  };

  const subCategoryConfig = () =>
    partsConfig
      .find(c => c.id === listingType)
      ?.config?.options?.find(cat => cat.key === category)
      ?.subcategories?.find(subCat => subCat.key === subCategory)?.specifications;

  const categorySpecificationsConfig = () =>
    partsConfig.find(c => c.id === listingType)?.config?.options?.find(cat => cat.key === category)?.specifications;

  const conditionConfig = () => partsConfig.find(c => c.id === 'condition');

  const specificationFilters = (subCategoryConfig() || categorySpecificationsConfig() || []).concat(conditionConfig());

  const buildPartsAttributes = config => (rows, key) => {
    const publicDataValue = publicData[key];

    if (config === undefined) return [];

    if (key === 'category') {
      const label = undefined;
      const value = intl.formatMessage({ id: `categories.${category}` });

      return rows.concat({ key, value, label });
    }

    if (key === 'subCategory' && subCategory) {
      const label = undefined;
      const value = intl.formatMessage({ id: `sub_categories.${subCategory}` });

      return rows.concat({ key, value, label });
    }
    if (publicDataValue) {
      const option = config.find(c => c.id === key);

      const translationGroup = option?.translationGroup;
      const skipItemPageTranslations = option?.skipItemPageTranslations;

      const value =
        translationGroup && !skipItemPageTranslations
          ? intl.formatMessage({ id: `${translationGroup}.${publicDataValue}` })
          : option?.config?.options?.find(o => o.key === publicDataValue)?.label || publicDataValue;

      const label = option?.label || `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }

    return rows;
  };

  const brands = brandsConfig[listingType] || brandsConfig[subCategory] || undefined;
  const brandValue = brands?.config?.options?.find(b => b.value === brand)?.label;
  const brandAttributes = brandValue ? [{ key: 'brand', value: brandValue }] : [];

  const existingBasicInformationData = () => {
    switch (listingType) {
      case 'bike': {
        return BIKE_REQUIRED_FIELDS.reduce(pickExtendedData(filters), []).concat(brandAttributes);
      }
      case 'parts': {
        return BIKE_GEAR_REQUIRED_FIELDS.reduce(buildPartsAttributes(specificationFilters), []).concat(brandAttributes);
      }
      case 'winter': {
        return WINTER_REQUIRED_FIELDS.reduce(buildPartsAttributes(specificationFilters), []).concat(brandAttributes);
      }
      default:
        return [];
    }
  };

  const existingMoreDetailsData = () => {
    switch (listingType) {
      case 'bike': {
        return BIKE_MORE_DETAILS_FIELDS.reduce(pickExtendedData(filters), []);
      }
      case 'parts': {
        const moreDetailsPartsAttributes = availableSpecifications().filter(
          attribute => !BIKE_GEAR_REQUIRED_FIELDS.includes(attribute)
        );
        return moreDetailsPartsAttributes.reduce(buildPartsAttributes(specificationFilters), []);
      }
      case 'winter': {
        const moreDetailsWinterAttributes = availableSpecifications().filter(
          attribute => !WINTER_REQUIRED_FIELDS.includes(attribute)
        );
        return moreDetailsWinterAttributes.reduce(buildPartsAttributes(specificationFilters), []);
      }
      default:
        return [];
    }
  };

  const basicInformationData = existingBasicInformationData();
  const moreDetailsData = existingMoreDetailsData();
  const showMoreDetailsSection = moreDetailsData.length > 0 || description

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <>
      <div className={css.listingPageGroupLabelWrapper}>
        <label className={css.listingPageGroupLabel}>
          {intl.formatMessage({ id: 'listing_page.basic_information.label' })}
        </label>
      </div>
      <div className={css.basicDetailsSectionWrapper}>
        {basicInformationData.map(detail => (
          <div key={`${detail.key}_base`}>
            <div key={detail.key} className={css.sectionDescriptionPanel}>
              <label className={css.basicInformationLabel}>
                {intl.formatMessage({ id: `config.labels.${detail.key}` })}
              </label>
              <span className={css.sectionDescriptionPanelValue}>{detail.value}</span>
            </div>
            <div key={`${detail.key}_divider`} className={css.divider} />
          </div>
        ))}
      </div>
      <div className={css.sectionDetails}>
        { showMoreDetailsSection && (
          <Collapsible openByDefault={false} label={intl.formatMessage({ id: 'listing_page.more_details.label' })}>
            {moreDetailsData && (
              <ul className={css.details}>
                {moreDetailsData.map((detail, index) => (
                  <div key={`${detail.key}_base`}>
                    <div key={detail.key} className={css.sectionDescriptionPanel}>
                      <label className={css.basicInformationLabel}>
                        {intl.formatMessage({ id: `config.labels.${detail.key}` })}
                      </label>
                      <span className={css.sectionDescriptionPanelValue}>{detail.value}</span>
                    </div>
                    {index < moreDetailsData.length - 1 ? (
                      <div key={`${detail.key}_divider`} className={css.divider} />
                    ) : null}
                  </div>
                  ))}
                </ul>
              )}
            {description && (
              <>
                <label className={css.descriptionLabel}>
                  {intl.formatMessage({ id: `listing_page.seller_details.label` })}
                </label>
                <Linkify componentDecorator={componentDecorator}>
                  <p className={css.description}>{description}</p>
                </Linkify>
                </>
              )}
          </Collapsible>
        )}
        <div className={css.divider} />
      </div>
    </>
  );
}

export default SectionBasicInformation;
